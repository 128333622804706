import PackageKochi from 'assets/packages/package-kochin.jpeg'
import PackageMunnar from 'assets/packages/package-munnar.jpeg'
import PackageThekkady from 'assets/packages/package-thekkady.jpeg'
import Packagewagamon from 'assets/packages/package-wagamon.jpeg'
import PackageAlappy from 'assets/packages/package-alappuzha.jpeg'
import PackageVarkkala from 'assets/packages/package-varkkala.jpg'

export const kerala = [
  {
    "title": "Package 1",
    "url": Packagewagamon,
    "duration": "3N 2D",
    "id": "5eadff9a-379f-4e61-8766-aadc5d9a9654",
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/5eadff9a-379f-4e61-8766-aadc5d9a9654",
    "htmlFile": "/assets/Package-html/3D-2N COUPLE-Template Preview-Saveller Tours & Travels.html"
  },
  {
    "title": "Package 2",
    "duration": "4D 3N",
    "totalDays": "4",
    "id": "42b05b21-5165-4ee8-8f03-fd7936697cb4",
    "url": PackageAlappy,
    "htmlFile": "/assets/Package-html/4D-3N KERALA-Template Preview-Saveller Tours & Travels.html",
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/42b05b21-5165-4ee8-8f03-fd7936697cb4"
  },
  {
    "title": "Package 3",
    "duration": "5D 4N",
    "totalDays": "5",
    "id": "4dcf6210-8b6b-4c7c-a525-baf7726f2d7f",
    "url": PackageThekkady,
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/4dcf6210-8b6b-4c7c-a525-baf7726f2d7f",
    "htmlFile": "/assets/Package-html/5D 4N Highlights of Kerala- OPTION 1-Template Preview-Saveller Tours & Travels.html"
  },
  {
    "title": "Package 4",
    "duration": "5D 4N",
    "totalDays": "5",
    "url": PackageKochi,
    "id": "1e0864e4-1451-4eff-b3fd-900527498d2c",
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/1e0864e4-1451-4eff-b3fd-900527498d2c",
    "htmlFile": "/assets/Package-html/5D-4N HIGHLIGHTS OF KERALA OPTION 2-Template Preview-Saveller Tours & Travels.html"
  },
  {
    "title": "Package 5",
    "duration": "6D 5N",
    "totalDays": "6",
    "url": PackageMunnar,
    "id": "142ba84e-9fe6-41e1-93c2-249fc374520f",
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/142ba84e-9fe6-41e1-93c2-249fc374520f",
    "htmlFile": "/assets/Package-html/6D-5N TOUR WITH HOUSE BOAT STAY-Template Preview-Saveller Tours & Travels.html"
  },
  {
    "title": "Package 6",
    "duration": "8D 7N",
    "totalDays": "8",
    "url": PackageVarkkala,
    "id": "aa9d0575-1d76-435a-8c10-614b136e0091",
    "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/aa9d0575-1d76-435a-8c10-614b136e0091",
    "htmlFile": "/assets/Package-html/8D-7N KERLALA-Template Preview-Saveller Tours & Travels.html"
  }
]
