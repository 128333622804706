import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './ImageGallery.css';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useResponsiveLayout = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isMobile };
};

const ImageGallery = ({ images }) => {
    const [currentStartIndex, setCurrentStartIndex] = useState(0);
    const [isCarouselHovered, setIsCarouselHovered] = useState(false);
    const { isMobile } = useResponsiveLayout();
    const totalImages = images?.length || 0;
    const scrollContainerRef = useRef(null);

    const navigate = useNavigate();

    const next = useCallback(() => {
        if (!isMobile) {
            setCurrentStartIndex((prevIndex) => (prevIndex + 1) % totalImages);
        }
    }, [totalImages, isMobile]);

    const prev = useCallback(() => {
        if (!isMobile) {
            setCurrentStartIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
        }
    }, [totalImages, isMobile]);

    useEffect(() => {
        let interval;
        if (!isCarouselHovered && !isMobile) {
            interval = setInterval(next, 3000);
        }
        return () => clearInterval(interval);
    }, [isCarouselHovered, next, isMobile]);

    useEffect(() => {
        if (isMobile && scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            let isScrolling = false;
            let startX;
            let scrollLeft;

            const handleTouchStart = (e) => {
                startX = e.touches[0].pageX - container.offsetLeft;
                scrollLeft = container.scrollLeft;
                isScrolling = true;
            };

            const handleTouchMove = (e) => {
                if (!isScrolling) return;
                e.preventDefault();
                const x = e.touches[0].pageX - container.offsetLeft;
                const walk = (x - startX) * 2;
                container.scrollLeft = scrollLeft - walk;
            };

            const handleTouchEnd = () => {
                isScrolling = false;
            };

            container.addEventListener('touchstart', handleTouchStart);
            container.addEventListener('touchmove', handleTouchMove);
            container.addEventListener('touchend', handleTouchEnd);

            return () => {
                container.removeEventListener('touchstart', handleTouchStart);
                container.removeEventListener('touchmove', handleTouchMove);
                container.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, [isMobile]);

    const getCurrentImages = () => {
        if (isMobile) {
            return images;
        }
        return Array.from({ length: 5 }, (_, i) => images[(currentStartIndex + i) % totalImages]);
    };

    if (!images || images.length === 0) {
        return <div>No images available.</div>;
    }

    const openItinerary = (image) => {
        if (!image || !image.htmlFile) {
            console.error("Invalid image data:", image);
            return;
        }

        ReactGA.event({
            category: 'Enquiry',
            action: 'click',
            label: 'Enquiry Button Click'
        });

        navigate(`/view-itinerary/${image.id}`);
    };

    return (
        <div
            className={`image-gallery-container ${isMobile ? 'mobile-scroll' : ''}`}
            onMouseEnter={() => setIsCarouselHovered(true)}
            onMouseLeave={() => setIsCarouselHovered(false)}
        >
            {!isMobile && (
                <div className="image-gallery-controls">
                    <button className="nav-button prev" onClick={prev} aria-label="Previous">
                        <ChevronLeft size={40} />
                    </button>
                    <button className="nav-button next" onClick={next} aria-label="Next">
                        <ChevronRight size={40} />
                    </button>
                </div>
            )}

            {isMobile ? (
                <div className="mobile-scroll-container" ref={scrollContainerRef}>
                    {getCurrentImages().map((img, index) => (
                        <div className="image-column mobile-column" key={index}>
                            <div
                                className="clickable-image-wrapper"
                                onClick={() => openItinerary(img)}
                                onKeyPress={(e) => e.key === 'Enter' && openItinerary(img)}
                                tabIndex={0}
                                role="button"
                                aria-label={`View itinerary for ${img.description}`}
                            >
                                <img
                                    className="d-block w-100"
                                    src={img.url}
                                    alt={img.alt}
                                    style={{ maxHeight: '300px', objectFit: 'cover' }}
                                />
                                <div className="image-caption roboto-regular">
                                    <h5>{img.description}</h5>
                                    <p>{img.totalDays} Days</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <Carousel controls={false} indicators={false} interval={null}>
                    <Carousel.Item>
                        <div className="image-row image-gallery-margin">
                            {getCurrentImages().map((img, index) => (
                                <div className="image-column" key={index}>
                                    <div
                                        className="clickable-image-wrapper"
                                        onClick={() => openItinerary(img)}
                                        onKeyPress={(e) => e.key === 'Enter' && openItinerary(img)}
                                        tabIndex={0}
                                        role="button"
                                        aria-label={`View itinerary for ${img.description}`}
                                    >
                                        <img
                                            className="d-block w-100"
                                            src={img.url}
                                            alt={img.alt}
                                            style={{ maxHeight: '300px', objectFit: 'cover' }}
                                        />
                                        <div className="image-caption roboto-regular">
                                            <h5>{img.description}</h5>
                                            <p>{img.totalDays} Days</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Carousel.Item>
                </Carousel>
            )}
        </div>
    );
};

export default ImageGallery;