export const kerala = [
    {
        "id": '5eadff9a-379f-4e61-8766-aadc5d9a9654',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/5eadff9a-379f-4e61-8766-aadc5d9a9654",
    },
    {
        "id": '42b05b21-5165-4ee8-8f03-fd7936697cb4',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/42b05b21-5165-4ee8-8f03-fd7936697cb4"
    },

    {
        "id": '4dcf6210-8b6b-4c7c-a525-baf7726f2d7f',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/4dcf6210-8b6b-4c7c-a525-baf7726f2d7f",
    },
    {
        "id": '1e0864e4-1451-4eff-b3fd-900527498d2c',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/1e0864e4-1451-4eff-b3fd-900527498d2c",
    },
    {
        "id": '142ba84e-9fe6-41e1-93c2-249fc374520f',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/142ba84e-9fe6-41e1-93c2-249fc374520f",
    },
    {
        "id": 'aa9d0575-1d76-435a-8c10-614b136e0091',
        "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/aa9d0575-1d76-435a-8c10-614b136e0091",
    }

]