import React, { createContext, useState, useContext } from 'react';

const ItineraryContext = createContext();

export const useItinerary = () => useContext(ItineraryContext);

export const ItineraryProvider = ({ children }) => {
    const [itineraryId, setItineraryId] = useState(null);

    return (
        <ItineraryContext.Provider value={{ itineraryId, setItineraryId }}>
            {children}
        </ItineraryContext.Provider>
    );
};

export { ItineraryContext };