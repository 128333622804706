import React, { useState, useEffect, useRef } from 'react';
import ExperienceModal from 'components/experiences-modal/experiences-modal';
import './ImageSlider.css';

import Kadhakali from 'assets/expereince-kerala/Cultural Programs.jpg'
import Edakkal from 'assets/expereince-kerala/edakkal caves.jpg'
import EnOoru from 'assets/expereince-kerala/En uru Tribal village.jpg'
import FishMarcket from 'assets/expereince-kerala/Chinese Fishing Net.jpg'
import Jew from 'assets/expereince-kerala/Jew street.jpg'
import Toddy from 'assets/expereince-kerala/Toddy Shop.jpg'
import Village from 'assets/expereince-kerala/Village visits.jpg'
import Munnar from 'assets/expereince-kerala/munnar.jpg'
import Kolukkumalai from 'assets/expereince-kerala/Kolukkumalai Sunrise.jpg'
import Kalaripayattu from 'assets/expereince-kerala/Kalaripayattu.jpg'
import Backwaters from 'assets/expereince-kerala/back waters.jpg'
import Ayurveda from 'assets/expereince-kerala/Ayurveda.jpg'
import Monsoon from 'assets/expereince-kerala/Monsoon experience_.jpg'
import Pooram from 'assets/expereince-kerala/Thrissur Pooram.jpg'
import Houseboat from 'assets/expereince-kerala/Houseboat.jpg'
import Elephant from 'assets/expereince-kerala/Elephant rides and elephant baths.jpg'
import Temple from 'assets/expereince-kerala/Famous temples.jpg'
import Beach from 'assets/expereince-kerala/Kerala beaches.jpg'
import Mangrove from 'assets/expereince-kerala/Mangrove Island.jpg'
import Race from 'assets/expereince-kerala/Snake Boat Race-Practice.jpg'
import Cultural from 'assets/expereince-kerala/Cultural Programs.jpg'
import Onam from 'assets/expereince-kerala/Onam celebration(Seasonal)_.jpg'
import Wildlife from 'assets/expereince-kerala/Wildlife tours.jpg'


import Mawlynnong from 'assets/experience-north/Mawlynnong.jpg'
import Kamakhya from 'assets/experience-north/The Kamakhya Temple.jpg'
import Cave from 'assets/experience-north/Mystical caves.jpg'
import Dwaki from 'assets/experience-north/dwaki .jpg'
import Kongthong from 'assets/experience-north/Kongthong.jpg'
import Pobitora from 'assets/experience-north/Pobitora Wildlife Sanctuary.jpg'
import Root from 'assets/experience-north/Root bridge.jpg'
import Waterfalls from 'assets/experience-north/Waterfalls.jpg'

import ReactGA from 'react-ga4';


function ImageSlider({ isKerala }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerRow, setItemsPerRow] = useState(5);
    const sliderRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [experienceContent, setExperienceContent] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const experienceDetails = {
        kerala: [
            {
                "imgURL": Kadhakali,
                "id": 1,
                "experienceName": "Boat Race Thiruvananthapuram",
                "descriptions": "Kathakali, an exquisite classical dance form originating from Kerala, India, is a vibrant tapestry of storytelling, music, drama, and elaborate costumes. Here's a descriptive passage that captures its essence: In the lush landscapes of Kerala, amidst swaying palm trees and the symphony of nature, Kathakali emerges as a radiant jewel, captivating hearts with its timeless allure. With roots deeply entrenched in ancient Indian traditions, this art form transcends mere performance to become a sacred ritual, a celebration of culture and spirituality.Each movement, each expression in Kathakali, is meticulously choreographed, infused with layers of symbolism and emotion. The dancers, adorned in opulent costumes and towering headdresses, metamorphose into divine beings, mythological characters, and earthly personas, breathing life into tales of love, valor, and cosmic battles.The rhythm of Kathakali is like a heartbeat, pulsating with the beat of the mizhavu drum and the melody of the chenda, creating an enchanting ambiance that transports the audience to mystical realms. Every gesture, every gesture, every nuanced facial expression narrates a story, unraveling the mysteries of the human psyche and the cosmic order."
            },
            {
                "imgURL": Edakkal,
                "id": 2,
                "experienceName": "Edakkal caves",
                "descriptions": "Edakkal Caves, nestled in the Wayanad district of Kerala, India, stand as a testament to ancient civilizations and human ingenuity. These natural rock formations showcase prehistoric petroglyphs, believed to date back over 8,000 years, depicting human and animal figures, symbols, and rituals. Perched atop Ambukuthi Hills, the caves offer panoramic views of the surrounding landscape, adding to their mystique and allure. Visitors can embark on a fascinating journey through time as they explore the narrow passages and chambers, marveling at the intricate carvings etched into the rock walls. Edakkal Caves are a captivating blend of history, archaeology, and natural beauty."
            },
            {
                "imgURL": EnOoru,
                "id": 3,
                "experienceName": "En uru Tribal village",
                "descriptions": "En Uru Tribal Village, nestled in the heart of Wayanad's verdant landscape in Kerala, India, offers a glimpse into the rich cultural heritage and traditional way of life of the indigenous tribal communities. Surrounded by lush forests and rolling hills, the village provides an immersive experience for visitors to learn about tribal customs, r;ituals, and craftsmanship. Visitors can engage with the friendly locals, participate in traditional dance and music performances, and explore the village's handicrafts and artisanal products. En Uru Tribal Village provides a unique opportunity to connect with the indigenous culture and support sustainable tourism initiatives in the region."
            },
            {
                "imgURL": Jew,
                "id": 4,
                "experienceName": "Jew street",
                "descriptions": "Jew Street, nestled in the heart of Mattancherry in Kochi, Kerala, India, is a historic thoroughfare steeped in multicultural heritage. Lined with quaint shops, antique stores, and spice emporiums, this vibrant street exudes an old-world charm that harks back to its Jewish roots. Visitors can explore the bustling market, browsing through an eclectic array of artifacts, jewelry, and handicrafts while soaking in the rich tapestry of cultures that define the area. The street's crowning jewel is the Paradesi Synagogue, a magnificent testament to Kochi's Jewish legacy. Jew Street offers a captivating journey through history, culture, and commerce in the heart of Kochi."
            },
            {
                "imgURL": FishMarcket,
                "id": 5,
                "experienceName": "Chinese Fishing Net",
                "descriptions": "The Chinese fishing nets of Fort Kochi are iconic symbols of Kerala's maritime heritage, dating back to the 14th century. These massive, intricately designed contraptions dot the coastline, operated by a system of counterweights and ropes. Watch as skilled fishermen rhythmically lower and raise the nets, a captivating sight against the backdrop of Kochi's scenic waterfront. Visitors can try their hand at operating the nets or simply observe the age-old fishing technique in action. As the sun sets, the nets silhouette against the sky, creating a picturesque scene that epitomizes the charm and allure of Fort Kochi's coastal landscape."
            },
            {
                "imgURL": Toddy,
                "id": 6,
                "experienceName": "Toddy Shop",
                "descriptions": "A toddy shop visit in Kerala offers a unique cultural experience, providing insight into the state's vibrant culinary traditions and social life. These rustic establishments serve as gathering places where locals and travellers alike come together to unwind and indulge in the region's traditional palm wine known as toddy. Savor the distinctive flavour of freshly tapped toddy, paired with delicious Kerala delicacies like spicy fish curry and tapioca. Engage in lively conversations with friendly patrons and soak in the laid-back ambience, enriched by the rhythmic beats of local music. A toddy shop visit is a memorable journey into Kerala's authentic flavours and convivial atmosphere."
            },
            {
                "imgURL": Village,
                "id": 7,
                "experienceName": "Village visits",
                "descriptions": "Village visits in Kerala offer tourists an authentic glimpse into the state's rich culture, traditions, and way of life. Explore quaint hamlets nestled amidst verdant landscapes, where time seems to stand still amidst lush paddy fields and coconut groves. Interact with friendly locals, experiencing their warm hospitality and traditional customs firsthand. Witness age-old practices like coir-making, pottery, and handloom weaving, preserving Kerala's artisanal heritage. Savor homemade delicacies prepared with fresh, locally sourced ingredients, and partake in cultural performances showcasing vibrant folk arts like Theyyam and Kathakali. Village visits in Kerala provide immersive experiences that foster a deeper appreciation for the state's rural charm and cultural diversity."
            },
            {
                "imgURL": Munnar,
                "id": 8,
                "experienceName": "Munnar ",
                "descriptions": "Munnar, nestled in the Western Ghats of Kerala, India, is a picturesque hill station renowned for its lush tea plantations, misty hills, and tranquil ambiance. Visitors to Munnar can embark on scenic drives through winding roads, visit tea estates to witness the tea-making process, and explore pristine natural attractions such as Eravikulam National Park, home to the endangered Nilgiri Tahr. Adventure enthusiasts can trek to Anamudi, the highest peak in South India, or indulge in activities like boating and wildlife spotting at Mattupetty Dam and Kundala Lake. With its cool climate and stunning vistas, Munnar offers a rejuvenating escape amidst nature's splendor."
            },
            {
                "imgURL": Kolukkumalai,
                "id": 9,
                "experienceName": "Kolukkumalai Sunrise",
                "descriptions": "Experience the breathtaking beauty of dawn atop Kolukkumalai, the world's highest organic tea plantation. Watch in awe as the sun peeks over the horizon, casting a golden glow on the misty hills and verdant valleys below, creating a magical spectacle that will leave an indelible mark on your soul."
            },
            {
                "imgURL": Kalaripayattu,
                "id": 10,
                "experienceName": "Kalaripayattu",
                "descriptions": " Kerala's ancient martial art, is a mesmerizing blend of combat techniques, physical prowess, and spiritual discipline. With roots dating back over 3,000 years, Kalaripayattu encompasses a diverse array of movements, including strikes, kicks, grappling, and weaponry. Practiced in traditional training grounds known as kalari, students undergo rigorous training to master agility, strength, and mental focus. Kalaripayattu's fluid movements and dynamic choreography captivate audiences worldwide, while its holistic approach promotes physical fitness, mental clarity, and spiritual growth. As one of the world's oldest martial arts forms, Kalaripayattu continues to inspire and empower practitioners, preserving Kerala's rich cultural heritage for future generations."
            },
            {
                "imgURL": Wildlife,
                "id": 11,
                "experienceName": "Wildlife tours",
                "descriptions": "Kerala's diverse ecosystems provide a haven for wildlife enthusiasts, offering captivating wildlife tours amidst lush forests, serene backwaters, and pristine mountains. Embark on thrilling safaris in national parks like Periyar and Wayanad, home to majestic elephants, elusive tigers, and exotic bird species. Cruise through Kerala's tranquil backwaters to spot rare aquatic creatures like otters and dolphins, while mangrove forests teem with unique biodiversity. Explore wildlife sanctuaries like Thattekad and Parambikulam, renowned for their rich avian populations and pristine habitats. Guided by expert naturalists, wildlife tours in Kerala offer unforgettable encounters with nature's wonders, making them a must-visit destination for nature lovers."
            },
            {
                "imgURL": Ayurveda,
                "id": 12,
                "experienceName": "Ayurveda ",
                "descriptions": "Kerala is renowned globally for its ancient healing tradition of Ayurveda, offering holistic wellness experiences amidst serene natural surroundings. Ayurvedic treatments in Kerala encompass a wide range of therapies, including rejuvenation, detoxification, and stress relief. Visitors can indulge in soothing massages with herbal oils, therapeutic steam baths, and herbal poultice applications, tailored to individual needs and ailments. Ayurvedic resorts and spas in Kerala offer serene environments conducive to healing, with expert Ayurvedic physicians prescribing personalized treatments and dietary recommendations. With its emphasis on natural remedies and holistic well-being, Ayurvedic treatments in Kerala offer a rejuvenating escape for body, mind, and soul."
            },
            {
                "imgURL": Backwaters,
                "id": 13,
                "experienceName": " Backwaters",
                "descriptions": "Experience the serene beauty of Kerala's backwaters, a labyrinthine network of interconnected lakes, rivers, and canals that weave through lush greenery and quaint villages. Embark on a leisurely cruise aboard traditional houseboats, gliding past coconut palms and picturesque landscapes, immersing yourself in the tranquility of nature's embrace."
            },
            {
                "imgURL": Houseboat,
                "id": 14,
                "experienceName": "Houseboat",
                "descriptions": "Indulge in the ultimate backwater experience aboard a luxurious houseboat in Kerala. Cruise along scenic waterways, savoring delicious Kerala cuisine prepared onboard, and unwind in comfort as you soak in panoramic views of the serene backwaters and picturesque villages."
            },
            {
                "imgURL": Race,
                "id": 15,
                "experienceName": " Snake Boat Race/Practice",
                "descriptions": "Witness the thrilling spectacle of Kerala's famous Snake Boat Races, where traditional longboats adorned with colorful flags and rowers compete in spirited races along the backwaters. Experience the excitement and camaraderie of this age-old tradition, dating back centuries in Kerala's cultural heritage."
            },
            {
                "imgURL": Cultural,
                "id": 16,
                "experienceName": "Cultural Programs",
                "descriptions": "Kerala's vibrant cultural scene comes alive through its famous cultural programs, offering a glimpse into the state's rich heritage and traditions. Kathakali, Kerala's classical dance-drama, mesmerizes audiences with its elaborate costumes, intricate facial expressions, and rhythmic footwork, depicting ancient tales from Hindu epics. Mohiniyattam, the graceful dance form known as the 'Dance of the Enchantress,' captivates with its fluid movements and lyrical storytelling. The vibrant beats of traditional percussion ensembles like Chenda Melam and Thayambaka echo through temples and festivals, while folk arts like Theyyam and Oppana showcase the rustic charm and vibrant spirit of Kerala's cultural diversity, making each performance a memorable experience."
            },
            {
                "imgURL": Mangrove,
                "id": 17,
                "experienceName": "Mangrove Island",
                "descriptions": "Discover the tranquil beauty of Mangrove Island, nestled amidst Kerala's backwaters. Cruise through winding waterways, surrounded by lush mangrove forests teeming with biodiversity. Witness the harmony of nature as you spot diverse bird species and aquatic life, offering a serene retreat amidst Kerala's pristine ecosystem."
            },
            {
                "imgURL": Beach,
                "id": 18,
                "experienceName": "Kerala beaches",
                "descriptions": "Kerala's beaches are a paradise for travelers seeking sun, sand, and serenity. With its 600-kilometer coastline along the Arabian Sea, Kerala boasts some of India's most pristine beaches. From the golden sands of Kovalam and Varkala to the secluded shores of Marari and Bekal, each beach offers a unique charm. Visitors can relax under swaying coconut palms, take leisurely walks along the shore, or indulge in thrilling water sports like surfing and parasailing. Kerala's beaches are not just about sun and surf; they also offer opportunities to explore local culture, cuisine, and vibrant coastal communities, making them a must-visit destination for beach lovers."
            },
            {
                "imgURL": Temple,
                "id": 19,
                "experienceName": "Famous temples",
                "descriptions": "Kerala is home to a plethora of ancient and revered temples that showcase the state's rich religious and architectural heritage. Among them, the Padmanabhaswamy Temple in Thiruvananthapuram stands out for its grandeur and devotion, dedicated to Lord Vishnu. The Guruvayur Temple in Thrissur is another prominent pilgrimage site, revered for its deity Lord Krishna. The Sabarimala Temple, nestled in the Western Ghats, attracts millions of devotees annually for the worship of Lord Ayyappa. Other notable temples include the Ettumanoor Mahadeva Temple, Chottanikkara Temple, and the Ambalappuzha Sri Krishna Temple, each holding centuries-old traditions and legends that enrich Kerala's cultural tapestry."
            },
            {
                "imgURL": Pooram,
                "id": 20,
                "experienceName": "Thrissur Pooram",
                "descriptions": "Kerala's grandest temple festival, is a dazzling spectacle of music, colors, and traditions that captivates foreigners seeking cultural experiences. Held annually at the Vadakkunnathan Temple in Thrissur, India, Pooram is a magnificent display of caparisoned elephants, vibrant parasols, and mesmerizing percussion music. Witness the thrilling percussion performances by hundreds of artists, showcasing the rich musical heritage of Kerala. Experience the electrifying atmosphere as rival temple processions converge, accompanied by dazzling fireworks displays that illuminate the night sky. Thrissur Pooram is a cultural extravaganza that offers foreigners a glimpse into the vibrant traditions and festivities of Kerala's heartland."
            },
            {
                "imgURL": Onam,
                "id": 21,
                "experienceName": "Onam celebration(Seasonal)",
                "descriptions": "Onam, the vibrant harvest festival of Kerala, India, is a celebration of joy, unity, and cultural heritage, perfect for foreigners seeking to immerse themselves in Kerala's rich traditions. The festival spans ten days and is marked by colorful flower decorations, traditional music, dance performances, and elaborate feasts. Witness the grandeur of Onam's iconic snake boat races, where teams compete in spirited races along Kerala's backwaters. Indulge in the mouth watering flavors of Onam Sadhya, a sumptuous vegetarian feast served on banana leaves, showcasing Kerala's culinary delights. Experience the warmth and hospitality of Kerala's people as they come together to celebrate Onam's spirit of prosperity and togetherness."
            },
            {
                "imgURL": Monsoon,
                "id": 22,
                "experienceName": "Monsoon experience",
                "descriptions": "The monsoon experience in Kerala is a symphony of sights, sounds, and sensations that rejuvenates the senses and transforms the landscape into a lush green paradise. As raindrops cascade from the sky, Kerala's backwaters swell with life, and its rivers gush with newfound vigor. Verdant hillsides come alive with cascading waterfalls, while tea plantations shimmer with dew-kissed leaves. The rhythmic pattern of rain on tiled roofs provides the soundtrack to lazy afternoons spent sipping hot tea. Despite occasional downpours, the monsoon brings a sense of renewal and vitality to Kerala, offering a unique and enchanting experience for visitors to cherish."
            },
            {
                "imgURL": Elephant,
                "id": 23,
                "experienceName": "Elephant rides and elephant baths",
                "descriptions": " in Kerala offer unforgettable encounters with these majestic creatures amidst the state's lush landscapes. Embark on a serene journey atop gentle giants, traversing through verdant forests or along tranquil riversides, providing a unique perspective of Kerala's natural beauty. After the ride, immerse yourself in the joyous experience of bathing these magnificent animals, scrubbing them gently with water and feeling their playful splashes. Interact with knowledgeable mahouts who share insights into elephant care and behavior, fostering a deeper appreciation for Kerala's rich cultural connection with these revered creatures. Elephant encounters in Kerala promise cherished memories of a lifetime."
            }
        ],
        norteast: [
            {
                "imgURL": Kongthong,
                "id": 1,
                "experienceName": "Kongthong",
                "descriptions": " nestled in Meghalaya's misty hills, enchants with a unique tradition—communicating through whistles. Here, each villager is assigned a personalized tune at birth, serving as their lifelong identity. This melodious language, passed down for generations, echoes through verdant valleys, carrying messages across vast distances. The whistles, harmonizing with nature's symphony, embody community bonds and cultural richness. Kongthong's ethereal soundscape, interwoven with daily life, preserves ancient customs in a modern era, captivating visitors with its enchanting melody. In this idyllic haven, the winds whisper tales of tradition, making Kongthong a harmonious testament to Meghalaya's cultural heritage."
            },
            {
                "imgURL": Dwaki,
                "id": 2,
                "experienceName": "Dawki",
                "descriptions": "a serene border town in Meghalaya, captivates with its crystal-clear waters of the Umngot River. Known as one of Asia's cleanest rivers, its translucent depths reveal pebbles and fish with breathtaking clarity. Dawki's famed suspension bridge offers panoramic views of the river's emerald expanse, inviting travelers to wander into its tranquil embrace. The town's bustling market, infused with local flavors and crafts, reflects the vibrant Khasi culture. Dawki's allure extends beyond its natural beauty, beckoning adventurers to explore its lush surroundings and immerse themselves in Meghalaya's rich tapestry of landscapes and traditions."
            },
            {
                "imgURL": Mawlynnong,
                "id": 3,
                "experienceName": "Mawlynnong",
                "descriptions": " Nestled in Meghalaya's verdant embrace, Mawlynnong stands as Asia's cleanest village, a testament to community pride and environmental stewardship. Its cobbled pathways wind through lush foliage, leading to picturesque bamboo houses adorned with vibrant gardens. Towering living root bridges, crafted by generations, connect the village to surrounding forests, offering glimpses of nature's architectural marvels. Mawlynnong's famed skywalk offers panoramic views of Bangladesh's plains, a testament to its breathtaking elevation. Here, eco-initiatives thrive, from organic farming to waste management, fostering sustainability and harmony with the land. Mawlynnong's ethereal beauty and eco-conscious ethos make it a haven for mindful travelers seeking solace in nature's embrace."
            },
            {
                "imgURL": Root,
                "id": 4,
                "experienceName": "Root bridge",
                "descriptions": " In Meghalaya's lush jungles, the ancient artistry of living root bridges flourishes, weaving nature's strength with human ingenuity. Crafted by the Khasi and Jaintia tribes over centuries, these organic marvels are formed by training the roots of the Ficus elastica tree across rivers, creating sturdy pathways. With time, these bridges grow stronger, intertwining with surrounding foliage in a symbiotic embrace. Each step across these arboreal structures resonates with echoes of tradition and resilience, bridging communities and landscapes. These living testaments to sustainability stand as enduring symbols of Meghalaya's harmonious relationship with its environment, captivating adventurers with their timeless allure."
            },
            {
                "imgURL": Waterfalls,
                "id": 5,
                "experienceName": "Waterfalls",
                "descriptions": "Meghalaya, the 'abode of clouds,' boasts a tapestry of enchanting waterfalls cascading through its misty valleys. Each cascade, like a brushstroke of nature's artistry, paints the landscape with its unique beauty. The majestic Nohkalikai, plunging from verdant cliffs, mesmerizes with its sheer drop into a turquoise pool below. The ethereal Krang Suri enchants with its crystalline waters, inviting travelers to bask in its serene embrace. From the thundering Elephant Falls to the hidden gems tucked away in remote corners, Meghalaya's waterfalls embody both the raw power and tranquil beauty of the region, beckoning adventurers to explore their timeless allure."
            },
            {
                "imgURL": Cave,
                "id": 6,
                "experienceName": "Mystical caves",
                "descriptions": "Meghalaya's subterranean realm harbors a labyrinth of mystical caves, whispering tales of ancient mysteries and geological wonders. The Mawsmai Cave, adorned with limestone formations, captivates explorers with its cathedral-like chambers and hidden passageways. Adventurers brave the depths of Krem Mawmluh, the state's fourth-longest cave, where underground rivers carve intricate channels through the darkness. The sprawling passages of Krem Liat Prah reveal fossils dating back millions of years, preserving fragments of prehistoric life. These underground marvels, steeped in myth and mystery, draw spelunkers and curious souls into their depths, offering glimpses of Meghalaya's hidden treasures and geological splendor."
            },
            {
                "imgURL": Pobitora,
                "id": 7,
                "experienceName": "Pobitora Wildlife Sanctuary",
                "descriptions": "a verdant jewel in Assam's crown, offers a mesmerizing safari experience amidst its rich biodiversity. Home to the world's highest population density of Indian one-horned rhinoceros, visitors embark on thrilling safaris through grassy plains and dense forests, encountering these majestic creatures in their natural habitat. As the safari unfolds, travelers are treated to sightings of wild buffalo, elephants, and various bird species, including the elusive Bengal florican. The sanctuary's tranquil lakes and meandering streams provide idyllic settings for wildlife viewing and birdwatching, offering an immersive journey into Assam's vibrant natural heritage and conservation efforts."
            },
            {
                "imgURL": Kamakhya,
                "id": 8,
                "experienceName": "The Kamakhya Temple",
                "descriptions": " nestled in the picturesque Nilachal Hill in Guwahati, Assam, is a revered Hindu shrine dedicated to the goddess Kamakhya. It's one of the most significant Shakti Peethas, where the yoni symbolizes the divine feminine power. The temple's architecture, with its distinctive beehive-shaped dome, attracts devotees and tourists alike. Legends shroud the temple in mystique, adding to its allure. During the annual Ambubachi Mela, thousands flock to seek blessings and witness unique rituals. Beyond religious significance, the temple stands as a symbol of cultural heritage, drawing visitors to experience its spiritual ambiance and panoramic views of the Brahmaputra River."
            }
        ]
    }



    useEffect(() => {
        const updateItemsPerRow = () => {
            if (window.innerWidth < 600) {
                setItemsPerRow(2);
            } else if (window.innerWidth < 900) {
                setItemsPerRow(3);
            } else {
                setItemsPerRow(5);
            }
        };
        updateItemsPerRow();
        window.addEventListener('resize', updateItemsPerRow);
        return () => window.removeEventListener('resize', updateItemsPerRow);
    }, [window.innerWidth]);

    useEffect(() => {
        setExperienceContent(isKerala ? experienceDetails.kerala : experienceDetails.norteast);
    }, [isKerala]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % experienceContent.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [experienceContent.length]);

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + experienceContent.length) % experienceContent.length);
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % experienceContent.length);
    };

    const getVisibleSlides = () => {
        const visibleSlides = [];
        for (let i = 0; i < itemsPerRow; i++) {
            const index = (currentIndex + i) % experienceContent.length;
            visibleSlides.push(experienceContent[index]);
        }
        return visibleSlides;
    };

    const openModal = (id) => {
        setSelectedIndex(id - 1);

        ReactGA.event({
            category: 'Experience',
            action: 'click',
            label: `Experience is clicked for detailed information`
        });

        const keralaAttractions = experienceDetails.kerala
        const northAttractions = experienceDetails.norteast
        setModalImage(isKerala? keralaAttractions: northAttractions);
        setShowModal(true);
    };

    const handleCurrentIndex = (index) => {
        setSelectedIndex(index);
    };

    const closeModal = () => {
        setSelectedIndex(null);
        setShowModal(false);
    };

    return (
        <div className="slider-container">
            <button className="arrow custom-left-arrow" onClick={goToPrevious}>‹</button>
            <div className="image-slider" ref={sliderRef}>
                {getVisibleSlides().map((image, index) => (
                    <div key={index} className="custom-s-slideHorizontal-cu">
                        <img
                            className="slide"
                            src={image?.imgURL}
                            alt={image?.experienceName}
                            onClick={() => openModal(image.id)}
                        />
                        <div className='experience-text-wrapper'>
                            <span className='merriweather-light text-uppercase experience-font'>{image?.experienceName}</span>
                        </div>
                    </div>
                ))}
            </div>
            <button className="arrow custom-right-arrow" onClick={goToNext}>›</button>
            {selectedIndex !== null && (
                <ExperienceModal showModal={showModal} closeModal={closeModal} currentIndex={selectedIndex}
                    updateCurrentIndex={handleCurrentIndex} image={modalImage} />
            )}
        </div>
    );
};

export default ImageSlider;