import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from 'assets/icons/saveller-logo.svg';
import LogoName from 'assets/icons/saveller-name.svg';
import Enquiry from 'assets/icons/enquery-mobile.svg';
import Contact from 'assets/contacticon.png';
import whatsapp from 'assets/icons/whatsapp.svg';
import Search from 'assets/icons/search.svg';
import YouTube from 'assets/icons/youtube.svg';
import Instagram from 'assets/icons/instagram.svg';
import FaceBook from 'assets/icons/download.svg';
import HamburgerIcon from 'assets/icons/hamburger-icons.svg';
import CloseIcon from 'assets/icons/close.svg';
import ContactUsModal from "components/contact-us-modal/contact-us-modal";
import EmailSvgIconComponent from "components/emailSvg";
import ReactGA from 'react-ga4';
import './navbar.css';
import { useItinerary } from 'components/image-gallary/ItineraryContext';
import { kerala } from 'components/constants/package-list'

export default function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isKeralaORNorth, setIsKeralaORNorth] = useState(false);
    const [urlText, setUrlText] = useState('');
    const searchRef = useRef(null);
    const overlayRef = useRef(null);
    const { itineraryId } = useItinerary();
    const [isItineraryLoaded, setIsItineraryLoaded] = useState(false);
    const [itineraryUrl, setItineraryUrl] = useState(null);
    const [isShowNavItem, setIsShowNavItem] = useState(true)
    const [isViewItenerary, setIsViewItenerary] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('/view-itinerary') && itineraryId) {
            const itinerary = kerala.find((data) => data.id === itineraryId);
            if (itinerary) {
                const url = itinerary.website;
                setItineraryUrl(url);
            }
        }

    }, [location.pathname, itineraryId]);

    useEffect(() => {
        if (location.pathname.includes('/view-itinerary')) {
            setIsShowNavItem(false)

        } else {
            setIsShowNavItem(true)

        }

    }, [location.pathname]);


    useEffect(() => {
        if (location.pathname === '/kerala' || location.pathname === '/northeast') {
            setIsKeralaORNorth(true)
            setUrlText('Packages');
        } else {
            setUrlText('Destination');
            setIsKeralaORNorth(false)
        }
        if (location.pathname.includes('view-itinerary')) {
            setIsViewItenerary(true)
        }
    }, [location.pathname]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const ga = (label) => {
        ReactGA.event({
            category: 'Header',
            action: 'click',
            label: `${label} is clicked`,
        });
    };

    const openContactModal = () => {
        ga('enquiry');
        setIsContactModalOpen(true);
    };

    const closeNavContactModal = () => {
        setIsContactModalOpen(false);
    };

    const handleSearchClick = () => {
        ga('search');
        setShowSearch(true);
    };

    const handleClickOutside = (event) => {
        if (
            (searchRef.current && !searchRef.current.contains(event.target)) &&
            (overlayRef.current && !overlayRef.current.contains(event.target))
        ) {
            setShowSearch(false);
            setIsMenuOpen(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        if (event.key === 'Enter') {
            const searchPath = getSearchPath(searchTerm.toLowerCase());
            if (searchPath) {
                navigate(searchPath);
                setShowSearch(false);
                setIsMenuOpen(false);
            } else {
                alert(`No results found for "${searchTerm}".`);
            }
        }
    };

    const getSearchPath = (term) => {
        if (term.startsWith('ke')) {
            return '/kerala';
        } else if (term.startsWith('no')) {
            return '/northeast';
        } else if (term.startsWith('leh')) {
            return '/leh-ladakh';
        } else if (term.startsWith('him')) {
            return '/himachal';
        }
        return null;
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = '+917012164577';
        const message = `Hello, I want to enquire about your services.`;
        let url;

        ga('whatsapp');

        if (location.pathname === '/view-itinerary') {
            url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(`${message} for the ${itineraryUrl}`)}`;
        } else {
            url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        }

        window.open(url, '_blank'); // Now `url` is accessible here
    };

    const handleContactClick = () => {
        const phoneNumber = '+917012164577';
        const url = `tel:${phoneNumber}`;
        ga('contact');
        window.location.href = url;
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavLinkClick = (event, sectionId) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        ga(section);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setIsMenuOpen(false);
        }
    };

    const closeMenu = () => {
        ga('close');
        setIsMenuOpen(false);
    };

    const openFacebookProfile = () => {
        const profileUrl = 'https://www.facebook.com/people/Saveller/61554212098101/?mibextid=LQQJ4d';
        ga('facebook');
        window.open(profileUrl, '_blank');
    };

    const openInstagramProfile = () => {
        const profileUrl = 'https://www.instagram.com/save.ller/';
        ga('instagram');
        window.open(profileUrl, '_blank');
    };

    const openYoutubeProfile = () => {
        const profileUrl = 'https://www.youtube.com/@Saveller';
        ga('youtube');
        window.open(profileUrl, '_blank');
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div className="contact-navbar">
                <div className="contact-container">
                    <div className="contact-left">
                        <EmailSvgIconComponent />
                        <p style={{ paddingLeft: '10px' }}>contact@saveller.com</p>
                    </div>
                    <div className="contact-right">
                        <a onClick={openFacebookProfile} target="_blank" rel="noopener noreferrer">
                            <img src={FaceBook} alt="Facebook" className="facebook-logo" />
                        </a>
                        <a onClick={openInstagramProfile} target="_blank" rel="noopener noreferrer">
                            <img src={Instagram} alt="Instagram" className="header-icons" />
                        </a>
                        <a onClick={openYoutubeProfile} target="_blank" rel="noopener noreferrer">
                            <img src={YouTube} alt="YouTube" className="header-icons" />
                        </a>
                    </div>
                </div>
            </div>

            <div className={`overlay ${isMenuOpen ? 'show' : ''}`} ref={overlayRef} onClick={toggleMenu}></div>

            <div className="main-navbar">
                <div className="main-container">
                    <div className="navbar-brand">
                        <a href="/">
                            <img className="site-logo" src={Logo} alt="Logo" />
                            <img className="site-logo-name" src={LogoName} alt="Logo Name" />
                        </a>
                    </div>
                    <div className="navbar-toggle">
                        {!showSearch && (
                            <img
                                className="search-icon"
                                src={Search}
                                alt="Search Icon"
                                onClick={handleSearchClick}
                            />
                        )}
                        {showSearch && (
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                ref={searchRef}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchSubmit}
                            />
                        )}
                        <img
                            src={isMenuOpen ? CloseIcon : HamburgerIcon}
                            alt="Menu Icon"
                            className="menu-icon"
                            onClick={toggleMenu}
                        />
                    </div>
                    <div className={`navbar-links cinzel-saveller ${isMenuOpen ? 'open' : ''}`}>
                        <button className="close-menu-button" onClick={closeMenu}>
                            <img src={CloseIcon} alt="Close Menu" />
                        </button>
                        <a href="/" className="nav-link">Home</a>
                        {isViewItenerary && <a href="/kerala" className="nav-link">Kerala</a>}
                        {isShowNavItem && <a href={urlText.toLowerCase()} className="nav-link" onClick={(e) => handleNavLinkClick(e, urlText.toLowerCase())}>{urlText}</a>}
                        {(isKeralaORNorth && isShowNavItem) && <a href='#experiences' className="nav-link" onClick={(e) => handleNavLinkClick(e, 'experiences')}>EXPERIENCES</a>}
                        {isShowNavItem && <a href="#reviews" className="nav-link" onClick={(e) => handleNavLinkClick(e, 'reviews')}>Reviews</a>}
                        <a href="#contactUs" className="nav-link" onClick={(e) => handleNavLinkClick(e, 'contactUs')}>Contact Us</a>
                        {(!showSearch && isShowNavItem) && (
                            <img
                                className="search-icon"
                                src={Search}
                                alt="Search Icon"
                                onClick={handleSearchClick}
                            />
                        )}
                        {showSearch && (
                            <div className="search-input-container">
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search..."
                                    ref={searchRef}
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onKeyDown={handleSearchSubmit}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobile Contact Navbar */}
            <div className="mobile-contact-navbar">
                <img
                    className="navbar-feature-icon"
                    src={whatsapp}
                    alt="WhatsApp Icon"
                    onClick={handleWhatsAppClick}
                />
                <img
                    className="navbar-feature-icon"
                    src={Contact}
                    alt="Contact Icon"
                    onClick={handleContactClick}
                />
                <img className="navbar-feature-icon-enquiry" onClick={openContactModal} src={Enquiry} alt="Enquiry Icon" />
            </div>
            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeNavContactModal} />
        </div>
    );
}
