import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'container/home/home';
import Packages from 'container/packages/packages';
import ComingSoon from 'container/comming-soon/comming-soon';
import Privacy from 'container/terms-condition/terms-condition';
import ScrollableComponent from './ScrollableCompoent';
import ViewItinerary from './components/image-gallary/view-itinerary';   


function AppRoutes() {
  return (
    <ScrollableComponent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kerala" element={<Packages />} />
        <Route path="/northeast" element={<ComingSoon />} />
        <Route path="/goa" element={<ComingSoon />} />
        <Route path="/himachal" element={<ComingSoon />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/booking-cancellation-policy" element={<Privacy />} />
        <Route path="/view-itinerary" element={<ViewItinerary />} />
        <Route path="/view-itinerary/:id" element={<ViewItinerary />} />

      </Routes>
    </ScrollableComponent>
  );
}

export default AppRoutes;
