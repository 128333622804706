import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import ImageGallery from "components/image-gallary/ImageGallery";
import CustomSlider from "components/custom-slider/custom.slider";
import PartnerHotels from "components/partner/partner";
import CustomerSlider from "components/customer-slider/customerSlider";
import HomeSlider from "components/home-carousel/home-casousel";
import ContactUsModal from "components/contact-us-modal/contact-us-modal";

import whatsapp from 'assets/icons/whatsapp.svg';
import ContactSaveller from 'assets/contactusbook.svg';

import PackageKochi from 'assets/packages/package-kochin.jpeg'
import PackageMunnar from 'assets/packages/package-munnar.jpeg'
import PackageThekkady from 'assets/packages/package-thekkady.jpeg'
import Packagewagamon from 'assets/packages/package-wagamon.jpeg'
import PackageAlappy from 'assets/packages/package-alappuzha.jpeg'
import PackageVarkkala from 'assets/packages/package-varkkala.jpg'

import PackageNorth1 from 'assets/packages/north1.jpeg'
import PackageNorth2 from 'assets/packages/north2.jpeg'

import "./style.css";
import "container/home/home.style.css";

import Kerala1 from 'assets/landing-kerala/KERALA 1 (1).png'
import Kerala2 from 'assets/landing-kerala/KERALA 2 (1).png'
import North1 from 'assets/landing-north/MEGHALAYA.png'
import North2 from 'assets/landing-north/MEGHALAYA 2.png'

import ReactGA from 'react-ga4';

const Packages = (props) => {
    const location = useLocation();

    const keralaPackageDescriptions = {
        name: 'Gods own country',
        description: `Embark on a journey to Kerala, the heart of India's tropical splendor, with Savellers. Known as "God's Own Country," Kerala invites you to experience its lush backwaters, pristine beaches, and verdant hills. With Savellers,
                    your adventure is crafted to be as unique as you are, blending personalized experiences with affordability.`
    }

    const northEastDescription = {
        name: 'Seven Sisters',
        description: `Explore Assam's tea gardens, Kaziranga's wildlife, and Guwahati's culture, then discover Meghalaya's living root bridges, Mawlynnong village, and Shillong's landscapes with Saveller's tailored tour packages. Immerse yourself in Northeastern charm, from Assamese cuisine delights to Meghalaya's trekking adventures, ensuring a memorable journey of natural beauty and cultural richness with Saveller.`
    }

    const packageDetails = {

        kerala: [
            {
                "title": "ALLEPPEY  THEKKADY  VAGAMON",
                "url": Packagewagamon,
                "duration": "3N 2D",
                "totalDays": "3",
                "id": '5eadff9a-379f-4e61-8766-aadc5d9a9654',
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/5eadff9a-379f-4e61-8766-aadc5d9a9654",
                "htmlFile": "assets/Package-html/3D-2N COUPLE-Template Preview-Saveller Tours & Travels.html"

            },
            {
                "title": "MUNNAR  VAGAMON  ALLEPPEY",
                "duration": "4D 3N",
                "totalDays": "4",
                "id": '42b05b21-5165-4ee8-8f03-fd7936697cb4',
                "url": PackageAlappy,
                "htmlFile": "assets/Package-html/4D-3N KERALA-Template Preview-Saveller Tours & Travels.html",
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/42b05b21-5165-4ee8-8f03-fd7936697cb4"
            },

            {
                "title": "MUNNAR  VAGAMON  ALLEPPEY",
                "duration": "5D 4N",
                "totalDays": "5",
                "id": '4dcf6210-8b6b-4c7c-a525-baf7726f2d7f',
                "url": PackageThekkady,
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/4dcf6210-8b6b-4c7c-a525-baf7726f2d7f",
                "htmlFile": "assets/Package-html/5D 4N Highlights of Kerala- OPTION 1-Template Preview-Saveller Tours & Travels.html"
            },
            {
                "title": "MUNNAR  THEKKADY  ALLEPPEY",
                "duration": "5D 4N",
                "totalDays": "5",
                "url": PackageKochi,
                "id": '1e0864e4-1451-4eff-b3fd-900527498d2c',
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/1e0864e4-1451-4eff-b3fd-900527498d2c",
                "htmlFile": "assets/Package-html/5D-4N HIGHLIGHTS OF KERALA OPTION 2-Template Preview-Saveller Tours & Travels.html"

            },
            {
                "title": "MUNNAR  VAGAMON  THEKKADY  ALLEPPEY",
                "duration": "6D 5N",
                "totalDays": "6",
                "url": PackageMunnar,
                "id": '142ba84e-9fe6-41e1-93c2-249fc374520f',
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/142ba84e-9fe6-41e1-93c2-249fc374520f",
                "htmlFile": "assets/Package-html/6D-5N TOUR WITH HOUSE BOAT STAY-Template Preview-Saveller Tours & Travels.html"

            },
            {
                "title": "MUNNAR THEKKADY  ALLEPPEY KOCHI",
                "duration": "8D 7N",
                "totalDays": "8",
                "url": PackageVarkkala,
                "id": 'aa9d0575-1d76-435a-8c10-614b136e0091',
                "website": "https://savellertourstravels.apac1.toursoft.systems/templates/public/aa9d0575-1d76-435a-8c10-614b136e0091",
                "htmlFile": "assets/Package-html/8D-7N KERLALA-Template Preview-Saveller Tours & Travels.html"
            },

        ],
        northeast: [
            {
                "title": "Meghalaya",
                "duration": "7D 6N",
                "url": PackageNorth2,
                "totalDays": "7",
                "id": 1
            },
            {
                "title": "Assam- Meghalaya",
                "duration": "10D 9N",
                "url": PackageNorth1,
                "totalDays": "10",
                "id": 2
            }
        ]

    };

    const imageArray = {
        kerala: [
            { src: Kerala1, alt: "Kerala 1" },
            { src: Kerala2, alt: "Kerala 2" },
        ],
        northeast: [
            { src: North1, alt: "Kerala 1" },
            { src: North2, alt: "Kerala 2" },
        ]
    };

    const packageLocation = location.pathname.replace(/\//g, '');
    const [filter, setFilter] = useState({ min: 0, max: Infinity });
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [description, setDescription] = useState({});
    const [filteredPackageDetails, setFilteredPackageDetails] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [index, setIndex] = useState(0);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [isKerala, setIsKerala] = useState(false);
    const [isNortheast, setIsNorthEast] = useState(false);
    const [isHomeSliderVisible, setIsHomeSliderVisible] = useState(false);
    const footerRef = useRef(null);
    const homeSliderRef = useRef(null);
    const isMobileView = window.innerWidth <= 767 ? true : false;

    const openContactModal = () => {
        ReactGA.event({
            category: 'package ',
            action: 'click ',
            label: `enquiery click `
        });
        setIsContactModalOpen(true);
    };

    const closeNavContactModal = () => {
        setIsContactModalOpen(false);
        ReactGA.event({
            category: 'package ',
            action: 'click ',
            label: `enquery close button click `
        });
    };

    useEffect(() => {
        if (packageLocation === 'kerala') {
            setDescription(keralaPackageDescriptions);
            setFilteredPackageDetails(packageDetails.kerala);
            setCarouselImages(imageArray.kerala);
            setIsNorthEast(false);
            setIsKerala(true);
        } else if (packageLocation === 'northeast') {
            setDescription(northEastDescription);
            setFilteredPackageDetails(packageDetails.northeast);
            setCarouselImages(imageArray.northeast);
            setIsKerala(false);
            setIsNorthEast(true);
        }
    }, [packageLocation]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFooterVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsHomeSliderVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (homeSliderRef.current) {
            observer.observe(homeSliderRef.current);
        }

        return () => {
            if (homeSliderRef.current) {
                observer.unobserve(homeSliderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const details = packageDetails[packageLocation]?.filter(detail =>
            detail.totalDays >= filter.min && detail.totalDays <= filter.max
        );
        setFilteredPackageDetails(details);
    }, [filter, packageLocation]);

    const handleCurrentObjectIndex = (index) => {
        setIndex(index);
    };

    const handleWhatsAppClick = () => {

        ReactGA.event({
            category: 'home',
            action: 'click ',
            label: `whatsapp button is clicked `
        });

        const phoneNumber = '+971586381929';
        const message = 'Hello, I want to inquire about your services.';

        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <div className="package-top-spacing">
            <div ref={homeSliderRef}>
                <HomeSlider images={carouselImages} showHeader={true} packageLocationFromURL={packageLocation} />
            </div>
            <div>
                <div className="Heading cinzel-saveller heading-spacing">{description.name}</div>
                <div className="merriweather-light content-font-size text-color content-horiz-spacing">
                    {description.description}
                </div>
            </div>

            <div>
                <div className="Heading cinzel-saveller heading-spacing" id="packages" style={!isMobileView ? { scrollMarginTop: "4em" } : {}}>Packages</div>
                {/* <div>
                    <ButtonGroup onFilterChange={setFilter} isKerala={isKerala} />
                </div> z*/}
                <div>
                    <ImageGallery images={filteredPackageDetails} isMobileView={isMobileView} currentObjectItemIndex={handleCurrentObjectIndex} />
                </div>
                <div className={`home-button-container ${isFooterVisible || isHomeSliderVisible ? 'hidden' : ''}`}>
                    <img className='whatsUpContact' style={{cursor:"pointer"}} src={ContactSaveller} alt="WhatsApp" onClick={openContactModal} />
                    {/* <button id='enquiry-button' className="merriweather-light" onClick={openContactModal}>Enquiry</button> */}
                </div>
                <div className="content merriweather-light d-large-none">
                    Not finding the option you are looking for? Get in touch with us to build your dream holiday
                </div>
                <div className="content merriweather-light d-small-none">
                    <div>
                        Not finding the option you are looking for?</div>
                    <div>Get in touch with us to build your dream holiday</div>
                </div>
                <div className="contact-us-btn primaryButton merriweather-light" onClick={() => openContactModal()}>
                    Contact us
                </div>
            </div>

            <div>
                <div className="Heading cinzel-saveller heading-spacing" id="experiences" style={!isMobileView ? { scrollMarginTop: "4em" } : {}}>Experiences</div>
                <div className="experience-outer-container">
                    <CustomSlider isKerala={isKerala} />
                </div>
            </div>

            <section id="reviews" style={!isMobileView ? { scrollMarginTop: "8em" } : {}}>
                <div className="Heading cinzel-saveller heading-spacing">Our customers</div>
                <div className="content review-container">
                    <CustomerSlider />
                </div>
            </section>

            <div className="mb-5">
                <div className="Heading cinzel-saveller" id="resort-heading-text">Our partner resorts & hotels</div>
                <div className="content" id="partner-content-item-wrapper">
                    <PartnerHotels isKerala={isKerala} isNortheast={isNortheast} />
                </div>
            </div>
            <footer ref={footerRef}>
                {/* Footer content goes here */}
            </footer>

            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeNavContactModal} />
        </div>
    );
};

export default Packages;
