import React, { useState, useContext, useEffect } from 'react';
import './view-itinerary.css';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ContactUsModal from "components/contact-us-modal/contact-us-modal";
import ContactSaveller from 'assets/contactusbook.svg';
import { ItineraryContext } from './ItineraryContext';
import itineraryData from './packageDetails.json';
import { kerala } from "./Custom-Gallery/packageDetails.tsx";

import CustomGallery from './Custom-Gallery/CustomGallery'


const ViewItinerary = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const { id } = useParams();

    const { setItineraryId } = useContext(ItineraryContext);

    const itinerary = itineraryData.kerala.find((item) => item.id === id);

    const images = kerala;



    useEffect(() => {
        if (id) {
            setItineraryId(id);
        }
    }, [id, setItineraryId]);

    const openModal = () => {
        setIsContactModalOpen(true);
        ReactGA.event({
            category: 'enquiry',
            action: 'click ',
            label: `enquiry button from is clicked`
        });
    };

    const closeModal = () => {
        setIsContactModalOpen(false);
        ReactGA.event({
            category: 'enquiry',
            action: 'click ',
            label: `close is from itinerary clicked`
        });
    };

    const handleIframeLoad = () => {
        setIsIframeLoaded(true);
    };

    // Render the component with the found itinerary details
    return (
        <div style={{ position: "relative" }}>
            {isIframeLoaded && (
                <div className='home-button-container' style={{ position: "sticky", top: "90%" }}>
                    <img className='whatsUpContact' src={ContactSaveller} alt="WhatsApp" onClick={openModal} />
                </div>
            )}
            <iframe
                src={itinerary?.htmlFile}
                title="Package HTML"
                style={{
                    width: '100%',
                    height: '81vh',
                    border: 'none'
                }}
                onLoad={handleIframeLoad}
            />
            <div className="custom-gallery-container">
                <CustomGallery images={images} />
            </div>


            <ContactUsModal isOpenContactModal={isContactModalOpen} itineraryUrl={itinerary?.website} onCloseContact={closeModal} />
        </div>
    );
};

export default ViewItinerary;
