import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import NavBar from "components/nav-bar/navBar";
import "container/home/home.style.css";
import AppRoutes from "./route";
import Footer from "components/footer/footer";
import { initializeGA, logPageView } from './analytic';
import { ItineraryProvider } from 'components/image-gallary/ItineraryContext'; // Import the ItineraryProvider

function App() {
    const scrollRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        initializeGA();
        logPageView();
    }, []);

    useEffect(() => {
        if (scrollRef.current) scrollRef.current.scrollIntoView();
    }, [pathname]);

    return (
        <ItineraryProvider> {/* Wrap the entire app with ItineraryProvider */}
            <div className="App">
                <div ref={scrollRef} className="custom-navbar">
                    <NavBar />
                </div>
                <div className="route-content">
                    <AppRoutes />
                </div>
                <Footer />
            </div>
        </ItineraryProvider>
    );
}

export default App;