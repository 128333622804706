import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CustomGallery.css";
import './BottomScrollbar.css'
import { kerala } from "./packageDetails.tsx";
import ReactGA from "react-ga4";

const CustomGallery = () => {
    const images = kerala;
    const router = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const itemsRef = useRef([]);
    const pageParam = useParams()
    useEffect(() => {
        const { id } = pageParam;
        const index = images.findIndex((element) => element.id === id)
        if (index >= 0)
            setActiveIndex(index)
    }, [images, pageParam])
    useEffect(() => {
        itemsRef.current[activeIndex]?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "nearest",
        });
    }, [activeIndex]);
    const navigateToItem = (itemId) => {
        router(`/view-itinerary/${itemId}`);
        ReactGA.event({
            category: "view itinerary bottom panel",
            action: "swipe",
            label: `swiped to item with id ${itemId}`,
        });
    };

    const handleClick = (index, itemId) => {
        setActiveIndex(index)
        navigateToItem(itemId)
    };
    const BottomScrollBar = () => {
        return (
            <div className="bottom-scroll-bar">
                {images.map((item, index) => (
                    <div className={index !== activeIndex ? "scroll-item" : "scroll-item-selected"} key={index} ref={(el) => (itemsRef.current[index] = el)} onClick={() => handleClick(index, item.id)}  >
                        {item.title}
                    </div>
                ))}
            </div>
        );
    };
    return (
        BottomScrollBar()
    );
};

export default CustomGallery;